import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener('change', this.#submitForm)
  }

  #submitForm(event) {
    event.preventDefault()

    event.target.form.requestSubmit()
  }
}

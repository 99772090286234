import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['input', 'item', 'itemSection', 'itemSectionCounter', 'noResultsMessage']

  connect() {
    this.search()
  }

  search() {
    const inputTerm = this.inputTarget.value

    this.itemTargets.forEach(item => {
      const itemKeywords = item.getAttribute('data-client-search-keywords')
      const isMatchFound = this.#isMatching(itemKeywords, inputTerm)

      this.#toggleVisibility(item, isMatchFound)
    })

    this.itemSectionTargets.forEach(itemSection => {
      const visibleItemsCount = itemSection.querySelectorAll('[data-client-search-target="item"]:not(.hidden)').length
      this.#toggleVisibility(itemSection, !!visibleItemsCount)

      const counter = itemSection.querySelector('[data-client-search-target="itemSectionCounter"]')
      if (counter) {
        counter.textContent = `(${visibleItemsCount})`
      }
    })

    this.#toggleVisibility(this.noResultsMessageTarget, this.itemTargets.every(item => item.classList.contains('hidden')))
  }

  #isMatching(searchSpace, query) {
    return searchSpace.trim().toLowerCase().includes(query)
  }

  #toggleVisibility(item, isVisible) {
    if (isVisible) {
      item.classList.remove('hidden')
    } else {
      item.classList.add('hidden')
    }
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'tab', 'tabContent' ]
  static values = { activeTab: { type: Number, default: 0 } }
  static classes = [ 'activeTab' ]

  connect() {
    this.#activateTab(this.activeTabValue)
  }

  handleActivateEvent(event) {
    this.#deactivateTab(this.activeTabValue)
    this.#activateTab(event.params.id)
    this.activeTabValue = event.params.id
  }

  #activateTab(index) {
    this.tabTargets[index].classList.add(...this.activeTabClasses)
    this.tabContentTargets[index].classList.remove('hidden')
  }

  #deactivateTab(index) {
    this.tabTargets[index].classList.remove(...this.activeTabClasses)
    this.tabContentTargets[index].classList.add('hidden')
  }
}

import {Controller} from "@hotwired/stimulus"

const CLOSE_DELAY_IN_MS = 210

export default class ModalController extends Controller {
  static values = {
    target: String
  }

  connect() {
    this.#modal.addEventListener('click', this.#onClickOutsideDialog.bind(this))

    this.#modal.addEventListener('cancel', (event) => {
      event.preventDefault()

      this.close()
    })

    if (this.#shouldAutoOpen) {
      this.open()
    }
  }

  open() {
    this.#modal.showModal()
    this.#toggleDocumentScroll(false)

    // Hack to trigger the transition when opening the modal
    setTimeout(() => this.#modal.classList.add('visible'), 1)
  }

  close() {
    this.#modal.classList.remove('visible')

    // Hack to display the transition before closing the modal
    setTimeout(() => {
      this.#modal.close()
      this.#toggleDocumentScroll(true)
      this.afterClose()
    }, CLOSE_DELAY_IN_MS)
  }

  afterClose() {
    // To be overridden by subclasses
  }

  #onClickOutsideDialog = (event) => {
    const isWithinBoundaries = event.composedPath().includes(
      this.#modal.querySelector('.modal__dialog')
    )
    if (isWithinBoundaries) {
      return;
    }

    this.close()
  }

  #toggleDocumentScroll(enable = false) {
    if (enable) {
      document.querySelector('body').classList.remove('modal-open')
    } else {
      document.querySelector('body').classList.add('modal-open')
    }
  }

  get #modal() {
    if (this.hasTargetValue) {
      return document.querySelector(this.targetValue)
    } else {
      return this.element.closest('.modal')
    }
  }

  get #shouldAutoOpen() {
    return this.#modal.getAttribute('data-modal-auto-open') === 'true'
  }
}

const flattenObject = (object, parentProperty, flatObject = {}) => {
  for (let property in object){
    let transformedProperty = parentProperty ? `${parentProperty}[${property}]` : property;

    if(typeof object[property] == 'object'){
      flattenObject(object[property], transformedProperty, flatObject);
    } else {
      flatObject[transformedProperty] = object[property];
    }
  }

  return flatObject;
}

const buildSearchParams = (payload) => new URLSearchParams(
  flattenObject(payload)
)

export default buildSearchParams

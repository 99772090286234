import ModalController from "./modal_controller.js";

export default class extends ModalController {
  afterClose() {
    super.afterClose()

    this.element.parentElement.removeAttribute("src")
    this.element.remove()
  }
}

import {Controller} from "@hotwired/stimulus"
import Sortable from "sortablejs"
import {csrfToken} from "../libs/csrf.js";

export default class extends Controller {
  static values = {
    payloadRoot: String,
    positionColumn: String
  }

  connect() {
    this.sortable = Sortable.create(this.element, {
      handle: '[data-sortable-target="handle"]',
      onSort: this.#updatePosition.bind(this)
    })
  }

  async #updatePosition(event) {
    const element = event.item
    const position = Array.from(element.parentNode.children).indexOf(element)
    const updateUrl = element.dataset.updateUrl

    const positionSuccessfullyUpdated = await this.#updatePositionOnServer(updateUrl, position)
    if (!positionSuccessfullyUpdated) {
      this.#resetPositions()
      return
    }

    this.#reloadElement(element)
  }

  #reloadElement(element) {
    if (!element.dataset.turboSrc) {
      return
    }

    element.src = element.dataset.turboSrc
  }

  #resetPositions() {
    const elementsSortedByOriginalPosition = Array.from(this.element.children)
      .sort((a, b) => a.dataset.position - b.dataset.position)
      .map(element => element.dataset.id)

    this.sortable.sort(elementsSortedByOriginalPosition)
  }

  async #updatePositionOnServer(url, position) {
    try {
      await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
        },
        body: JSON.stringify(this.#buildPayload(position))
      })

      return true
    } catch (error) {
      console.error('Error:', error)
      return false
    }
  }

  #buildPayload(position) {
    const payload = {}
    payload[this.payloadRootValue] = {}
    payload[this.payloadRootValue][this.positionColumnValue] = position
    return payload
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    targets: String
  }

  connect() {
    this.element.addEventListener('submit', this.#disableFormElements.bind(this))
  }

  #disableFormElements() {
    this.#forms.forEach((form) => {
      form.querySelectorAll('input').forEach((element) => {
        element.classList.add('disabled')
      })
    })
  }

  get #forms() {
    if (this.hasTargetsValue) {
      return document.querySelectorAll(this.targetsValue)
    } else {
      return this.element.closest('form')
    }
  }
}

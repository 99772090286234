import {Controller} from "@hotwired/stimulus"
import AnalyticsAPI from "../services/analytics-api"

export default class extends Controller {
  initialize() {
    this.api = new AnalyticsAPI()
    this.triggered = false

    this.registerOnClickEvent()
  }

  get impressionId() {
    return this.element.closest('[data-impression-id]').getAttribute('data-impression-id') ?? null
  }

  get payload() {
    return {
      ...JSON.parse(this.element.getAttribute('data-click-payload')),
      impression_id: this.impressionId
    }
  }

  registerOnClickEvent() {
    this.element.addEventListener('click', this.onClick.bind(this))
  }

  onClick() {
    if (this.triggered) {
      return
    }

    this.triggered = true
    this.api.trackClick(this.payload)
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['input']

  initialize() {
    this.currentSearchTerm = this.inputTarget.value
  }

  handleSearchTermUpdate(event) {
    if (this.#clearEvent(event)) this.#handleClearAction()
  }

  // proprietary way of detecting if the user clicked 'x' icon in search input
  #clearEvent(event) {
    return event.inputType == undefined && event.data == undefined
  }

  #handleClearAction() {
    // only submit if its neded
    if (this.currentSearchTerm != '') this.element.requestSubmit()
  }
}

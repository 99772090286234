import {Controller} from "@hotwired/stimulus"
import AnalyticsAPI from "../services/analytics-api"
import {isBot} from "../libs/bot-detector"

export default class extends Controller {
  initialize() {
    this.api = new AnalyticsAPI()
    this.triggered = false

    this.connectToObserver()
  }

  get payload() {
    return this.element.getAttribute('data-impression-payload')
  }

  connectToObserver() {
    window.viewportObserver.observe(this.element)

    this.element.addEventListener('onViewportIntersecting', this.onViewportIntersecting.bind(this))
  }

  async onViewportIntersecting() {
    if (this.triggered || isBot()) {
      return
    }

    this.triggered = true

    const response = await this.api.trackImpression(this.payload)
    this.setImpressionId(response.data.id)
  }

  setImpressionId(id) {
    this.element.setAttribute('data-impression-id', id)
  }
}

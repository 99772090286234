import { Controller } from "@hotwired/stimulus"
import uFuzzy from '@leeoniya/ufuzzy'

export default class extends Controller {
  static targets = ['option', 'search']
  static values = { limit: { type: Number, default: 5 } }

  initialize() {
    this.labelValues = this.optionTargets.map(option => option.dataset.filterLabel)
    this.fuzzySearcher = new uFuzzy({ intraMode: 0 })
  }

  connect() {
    if (this.optionTargets.length <= this.limitValue) this.searchTarget.classList.add('hidden')
  }

  filterOptions(event) {
    const matchingOptionIds = this.fuzzySearcher.filter(this.labelValues, event.target.value)

    this.optionTargets.forEach((option, index) => {
      if (matchingOptionIds == null || matchingOptionIds.includes(index)) {
        this.showOption(option)
      } else {
        this.hideOption(option)
      }
    })
  }

  hideOption(option) {
    option.classList.add('hidden')
  }

  showOption(option) {
    option.classList.remove('hidden')
  }
}

import {Application} from '@hotwired/stimulus'
import {registerControllers} from 'stimulus-vite-helpers'
import RailsNestedForm from '@stimulus-components/rails-nested-form'

const application = Application.start()
const genericControllers = import.meta.glob('./*_controller.js', {eager: true})

// TODO: setup JS testing with Jest
// https://bootrails.com/blog/how-to-set-up-JavaScript-testing-for-Rails-7-with-jest/

registerControllers(application, genericControllers)
application.register('nested-form', RailsNestedForm)

const componentControllers = import.meta.glob(
  "./../../../frontend/components/**/controller.js",
  { eager: true }
)

for (let path in componentControllers) {
  let module = componentControllers[path];
  let name = path
    .match(/frontend\/components\/(.+)\/controller\.js$/)[1]
    .replaceAll("/", "-")
    .replaceAll("_", "-");

  application.register(name, module.default);
}

export default application;

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { triggerElementId: String }

  initialize() {
    this.triggerElement = document.getElementById(this.triggerElementIdValue)
  }

  connect() {
    this.triggerPosition = this.#getTopPosition(this.triggerElement)
    this.#toggleStickyness()

    document.addEventListener('scroll', this.#toggleStickyness.bind(this), {
      passive: true
    })
  }

  disconnect() {
    document.removeEventListener('scroll', this.#toggleStickyness.bind(this))
  }

  #toggleStickyness() {
    if (window.scrollY > this.triggerPosition) {
      this.element.classList.add('fixed')
      this.element.classList.remove('hidden')
    } else {
      this.element.classList.add('hidden')
      this.element.classList.remove('fixed')
    }
  }

  #getTopPosition(element) {
    return element.getBoundingClientRect().top + window.scrollY
  }
}

import buildSearchParams from "../libs/search-params";

export default class AnalyticsAPI {
  #baseURL = PUBLIC_ENV.marketplaceAnalyticsApi.baseUrl;
  #encodedTrackingKey = PUBLIC_ENV.marketplaceAnalyticsApi.trackingKey;

  async trackImpression(payload) {
    const request = new Request(
      this.buildURL('/impressions'),
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          impression: JSON.parse(payload),
          tracking_key: this.#encodedTrackingKey
        })
      }
    )

    const response = await fetch(request)
    return response.json();
  }

  trackClick(payload) {
    // BeaconAPI reliably sends an HTTP request even if user leaves the page
    navigator.sendBeacon(
      this.buildURL('/clicks'),
      buildSearchParams({
        click: payload,
        tracking_key: this.#encodedTrackingKey
      })
    )
  }

  buildURL(endpoint) {
    const sanitizedEndpoint = endpoint.replace(/^\//, '');
    return `${this.#baseURL}/${sanitizedEndpoint}`;
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'contentItem', 'expandIcon' ]
  static classes = [ 'open', 'closed' ]

  connect() {
    console.log(this.contentItemTargets)
  }

  toggle(event) {
    const index = event.params.itemId

    if (this.#isOpen(index)) {
      this.#close(index)
    } else {
      this.#open(index)
    }
  }

  #open(index) {
    const toggler = this.#togglerEl(index)
    const icon = this.#iconEl(index)

    toggler.classList.remove(this.closedClass)
    toggler.classList.add(this.openClass)
    icon.classList.add('rotate-180')

    toggler.dataset.accordionState = 'open'
  }

  #close(index) {
    const toggler = this.#togglerEl(index)
    const icon = this.#iconEl(index)

    toggler.classList.remove(this.openClass)
    toggler.classList.add(this.closedClass)
    icon.classList.remove('rotate-180')

    toggler.dataset.accordionState = 'closed'
  }

  #isOpen(index) {
    return this.#togglerEl(index).dataset.accordionState == 'open'
  }

  #togglerEl(index) {
    return this.contentItemTargets[index]
  }

  #iconEl(index) {
    return this.expandIconTargets[index]
  }
}

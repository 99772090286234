export default class ViewportObserver {
  static create() {
    return new IntersectionObserver((entries) => {
      entries
        .filter(entry => entry.isIntersecting)
        .forEach((entry) => {
          entry.target.dispatchEvent(new Event('onViewportIntersecting'))
        })
    }, {})
  }

  static findOrCreate() {
    return window.viewportObserver ?? ViewportObserver.create()
  }
}
